<template>
  <Popup v-model="screen" position="right" :style="{ width: '76%',height: '100%' }">
    <div v-for="(item, index) in firstList" :key="index">
      <div class="handtitle" v-if="item.hasOwnProperty('child')">{{item.name}}</div> 
      <!-- 一级 -->
      <template v-for="(child, idx) in item.child">
        <!-- 二级 -->
        <Collapse v-model="activeNames" :key="idx" @change="searchOptions" :accordion="true">
        
          <CollapseItem :title="child.name" :name="child.id">
          <!-- 三级 -->
          <template v-for="(grandson, grandsonidx) in options">
            <div class="screen-items" @click="toScreen(grandson)" :key="grandsonidx">
              <span>{{grandson.name}}</span>
            </div>
          </template>
          </CollapseItem>
        </Collapse>
      </template>
    </div>

    <div class="screen-price">
      <div class="screen-price-title">价格区间(元)</div>
      <div class="screen-price-input">
        <input type="text" />
        <Divider :style="{ width: '30px', borderColor: '#C0C0C0' }"></Divider>
        <input type="text" />
      </div>
    </div>
    <div class="screen-option">
      <div class="options" v-for="(item, index) in screenOptions" :key="index">
        <tag closeable size="large" type="primary" @close="close(index)">{{item.name}}</tag>
      </div>
    </div>
    <div class="screen-btn">
      <div @click="reset">重置</div>
      <div @click="confirm">确定</div>
    </div>
  </Popup>
</template>
<script>
import Goods from "@/api/goods";
import { Popup, Collapse, CollapseItem, Divider, Tag } from "vant";
export default {
  components: {
    Popup,
    Collapse,
    CollapseItem,
    Divider,
    Tag
  },
  data() {
    return {
      screen: false,
      activeNames: [],
      firstList: [],
      screenOptions: [],
      classId: "",
      options: []
    };
  },
  methods: {
    async searchOptions(id) {
      console.log(id)
      try {
        const res = await Goods.searchOptions(id)
        console.log(res)
        this.options = res.data.search_group;
      } catch (err) {
        console.log(err)
      }
    },
    // 确定
    confirm() {
      let ids = [];
      this.screenOptions.forEach(element => {
        ids.push(element.id);
      });
      console.log(ids.join());
      this.classId = ids.join();
      this.$emit("getClassId", this.classId);
      this.screen = false;
    },
    reset() {
      this.screenOptions = [];
    },
    // 关闭筛选项
    close(index) {
      console.log(index);
      this.screenOptions.splice(index, 1);
    },
    // 设置筛选项
    toScreen(child) {
      let options = {
        name: child.name,
        id: child.id,
        store: true
      };
      let state = false,
        idx = "";
      if (this.screenOptions.length > 0) {
        // this.screenOptions.push(options);
        for (let i = 0; i < this.screenOptions.length; i++) {
          const element = this.screenOptions[i];
          if (element.id == child.id) {
            idx = i;
            state = true;
            break;
          } else {
            state = false;
          }
        }
        console.log(state);
        setTimeout(() => {
          if (state) {
            this.screenOptions.splice(idx, 1);
          } else {
            this.screenOptions.push(options);
          }
        }, 100);
      } else {
        console.log(1222111);
        this.screenOptions.push(options);
      }
    },
    // 开启遮罩
    screenStatus() {
      this.screen = true;
      this.getGoodsList();
      this.screenOptions = [];
    },
    // 商品列表
    async getGoodsList() {
      try {
        const res = await Goods.getGoodsTree();
        if (res.code == 200) {
          this.firstList = res.data;
        }
        console.log(res);
        setTimeout(() => {
          this.loadingStatus = false;
        }, 1000);
      } catch (err) {
        console.log(err);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.screen-items {
  width: 30%;
  height: 44px;
  padding: 0 4px;
  text-align: center;
  line-height: 44px;
  color: #333333;
  float: left;
  margin: 4px 2px;
  background: rgba(244, 244, 244, 1);
  border-radius: 6px 6px 6px 6px;
}
.handtitle {
  padding: 0 15px;
  margin: 5px 0;
  color: #000;
  font-size: 16px;
  // background: #0b090914;
  // border-bottom: 1px solid #0b090914;
}
.screen-price {
  width: 100%;
  height: 110px;
  display: flex;
  flex-direction: column;
  .screen-price-title {
    padding-left: 10px;
    line-height: 40px;
    color: #333333;
    font-weight: 500;
  }
  .screen-price-input {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    input {
      border: none;
      width: 110px;
      height: 27px;
      border-radius: 40px;
      text-align: center;
      background: rgba(244, 244, 244, 1);
      border: 1px solid rgba(242, 242, 242, 1);
    }
  }
}
.screen-option {
  padding: 0px 10px;
  display: flex;
  flex-wrap: wrap;
  .options {
    margin: 5px;
  }
}
.screen-btn {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  width: 100%;
  height: 44px;
  font-weight: 100;
  font-size: 16px;
  color: #ffffff;
  div {
    text-align: center;
    flex: 1;
    line-height: 44px;
  }
  div:nth-child(1) {
    background: #ffb101;
  }
  div:nth-child(2) {
    background: #ff8000;
  }
}
</style>