<template>
  <div class="search-con">
    <div class="search-top">
      <div class="search-two-con">
        <span class="iconfont iconhoutui" @click="goBack"></span>
        <input
          type="text"
          class="search-icon"
          placeholder="请输入搜索的商家或商品名称"
          v-model="scarchCont"
        />
        <span class="iconfont iconsousuo" @click="getScarch"></span>
      </div>
    </div>
    <div class="search-tab">
      <ul>
        <li
          :class="[tabIdx == idx ? 'active' : '']"
          v-for="(item, idx) in tabs"
          :key="idx"
          @click="setIdx(idx)"
        >
          {{ item.text }}
          <span :class="[item.status ? item.righticon : '', 'iconfont']"></span>
        </li>
      </ul>
    </div>
    <ShopList
      :showTabs="false"
      :type="listType"
      :name="scarchCont"
      :searchId="searchId"
      class="marginTop"
      :styleStore="styleStore"
    ></ShopList>
    <Screen ref="screen" :screen="true" @getClassId="getClassId"></Screen>
  </div>
</template>
<script>
import Screen from "./screen";
import ShopList from "../home/enjoyshop/ShopList.vue";
export default {
  components: {
    Screen,
    ShopList
  },
  data() {
    return {
      styleStore: true,
      listType: "other",
      searchId: "",
      searchName: "",
      scarchCont: "",
      tabIdx: 0,
      screen: true,
      activeNames: [],
      tabs: [
        {
          text: "综合",
          righticon: "",
          status: false
        },
        {
          text: "销量",
          righticon: "",
          status: false
        },
        {
          text: "价格",
          righticon: "iconpaixu",
          status: true
        },
        {
          text: "店铺",
          righticon: "",
          status: false
        },
        {
          text: "",
          righticon: "iconswitch",
          status: true
        },
        {
          text: "筛选",
          righticon: "iconshaixuan1",
          status: true
        }
      ]
    };
  },
  watch: {
    scarchCont(val) {
      if (val == "") {
        this.listType = "store";
      }
    }
  },
  methods: {
    getClassId(val) {
      console.log(val);
      this.searchId = val;
      this.listType = "filtrate";
    },
    getScarch() {
      try {
        this.listType = "scarch";
      } catch (error) {
        console.log(error);
      }
    },
    setIdx(idx) {
      switch (idx) {
        case 5:
          this.$refs.screen.screenStatus();
          this.listType = "store";
          break;
        case 4:
          this.styleStore = !this.styleStore;
          this.tabIdx = idx;
          break;
        default:
          this.tabIdx = idx;
          break;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@mixin topPos() {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 45px;
  line-height: 45px;
  background: #ffffff;
  z-index: 999;
}
.search-two-con {
  @include topPos();
  display: flex;
  padding: 0 12px;
  position: fixed;
  align-items: center;
  justify-content: space-between;
  .iconfont {
    font-size: 20px;
  }
  .search-icon {
    width: 285px;
    height: 25px;
    display: flex;
    align-items: center;
    color: #8c8c8c;
    border: none;
    padding-left: 10px;
    background: rgba(244, 244, 244, 1);
    border-radius: 5px;
    span {
      margin-left: 10px;
    }
  }
}
.search-con {
  width: 100%;
  height: 100%;
  background: #f4f4f4;
  .search-top {
    width: 100%;
    height: 45px;
    background: #ffffff;
  }
  .search-tab {
    width: 100%;
    height: 35px;
    position: fixed;
    color: #333333;
    z-index: 1000;
    background: #ffffff;
    ul {
      width: 100%;
      height: auto;
      display: flex;
      li {
        flex: 1;
        font-size: 14px;
        text-align: center;
      }
      li:nth-child(5) {
        span {
          font-size: 20px;
        }
      }
      li:nth-child(6) {
        span {
          font-size: 14px;
          margin-left: 4px;
        }
      }
      .active {
        color: #ff8000;
      }
    }
  }
}
.marginTop {
  margin-top: 30px;
}
</style>
